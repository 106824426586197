@import "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap";
@import "../src/assets/variables/variables.css";
@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  font-family: "Open Sans";
  color: #fff;
}
html {
  background-color: #000;
}
@media only screen and (max-width: 768px) {
  html {
    background-color: #fff;
  }
}
h1 {
  font-size: 40px;
}
h2 {
  font-size: 32px;
}
h3 {
  font-size: 20px;
  font-weight: 200;
  line-height: 1.25em;
}
h4 {
  font-size: 16px;
}
p {
  font-size: 14px;
}

.black-bkr {
  background-color: #000;
}
@media only screen and (max-width: 768px) {
  .black-bkr {
    background-color: #fff;
  }
}
.app-header {
  height: 50px;
  background-color: #3b3734;
  display: flex;
  margin-top: -1px;
}
.app-header a {
  color: #fff;
  cursor: pointer;
  z-index: 99;
}
.app-header img {
  height: 100%;
}
@media only screen and (max-width: 480px) {
  .app-header {
    z-index: 999;
    height: 50px;
    padding-right: 1rem;
  }
}
@media only screen and (min-width: 481px) and (max-width: 1024px) {
  .app-header {
    z-index: 999;
    height: 75px;
    padding-right: 1rem;
  }
}
.purple-button {
  padding: 3px 16px 5px 16px;
  background-color: #702682;
  border: 1px solid #fff;
  border-radius: 32px;
  color: #fff;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
}
xrin54 .purple-button:hover {
  background-color: #af45c9;
}

@media only screen and (max-width: 768px) {
  .purple-button {
    border-radius: 20px;
  }
}
.orange-button {
  background-color: #f49800;
  border: 2px solid #fff;
  border-radius: 22px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.orange-button:disabled {
  opacity: 0.5;
}
.orange-button:hover {
  background-color: #ffc15b;
}
@media only screen and (max-width: 768px) {
  .orange-button {
    border-radius: 20px;
  }
}
.orange-text {
  color: #f49800;
}
@media only screen and (max-width: 1024px) {
  .homepage-bkr {
    background-color: transparent;
    background: url("../public/images/Group 5.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}
@media only screen and (max-width: 1024px) {
  .homepage-bkr::after {
    content: "";
    display: block;
    padding-bottom: 500px;
  }
}
@media only screen and (max-width: 480px) {
  .homepage-bkr::after {
    padding-bottom: 363px;
  }
}
.purple-rectangle {
  background-color: #702682;
  border-radius: 30px;
  height: calc(100% + 80px);
  margin-top: -5rem;
  padding: 0 3rem 0 2rem;
}
@media only screen and (max-width: 768px) {
  .purple-rectangle {
    margin-top: -8px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    padding: 1rem;
  }
}
.countdown-number {
  font-size: 80px;
  font-weight: 300;
  padding: 4px 16px 0 16px;
  border-bottom: 1px solid #702682;
}
@media only screen and (max-width: 768px) {
  .countdown-number {
    font-size: 36px;
  }
}
.countdown-number html {
  background-color: #fff;
}
.countdown-dots {
  font-size: 50px;
  padding: 24px 4px;
}
@media only screen and (max-width: 768px) {
  .countdown-dots {
    font-size: 36px;
  }
}
.border-0 {
  border-width: 0 !important;
}
.purple-bg {
  background-color: #702682;
  color: #fff;
}
.orange-bg {
  background-color: #f49800;
  color: #fff;
}
.light-purple-text {
  color: #7a2d7f;
}
.black-bg {
  background-color: #000;
}
.dark-purple-text {
  color: #67338c;
}
.purple-text {
  color: #702682;
}
.slice-2-background-photo {
  background-image: url(../public/images/slice-2-image.jpg);
  background-size: cover;
}
.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.black-text-block p {
  color: #000;
}
.black-text-block span {
  color: #000;
}
.orange-gradient {
  background: linear-gradient(to bottom, rgba(255, 0, 0, 0) 0%, rgba(244, 152, 0, 0.35) 100%);
}
.light-gray {
  color: #d3d3d3;
}
.gray-bg {
  background: #807b78;
  background: linear-gradient(90deg, #55514f 0%, #55514f 100%);
}
.gradient-gray-image::after {
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  background: #807b78;
  background: linear-gradient(0deg, #383636 0%, rgba(85, 81, 79, 0.1601234244) 100%);
}
.light-gray-bg {
  background: #fff;
  background: linear-gradient(180deg, white 0%, #ededed 100%);
}
@media only screen and (max-width: 768px) {
  .light-gray-bg {
    background: #f8f8f8 0% 0% no-repeat padding-box;
  }
}
.custom-text-size h2 {
  font-size: 24px;
}
@media only screen and (max-width: 768px) {
  .custom-text-size h2 {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1280px) {
  .font-massive {
    font-size: 60px;
  }
}
.roadmap-height-calc {
  height: calc(100% - 300px);
  width: calc(100% - 200px);
}
.calculate-right {
  right: -50px;
}
@media only screen and (min-width: 1600px) {
  .calculate-right {
    right: -100px;
  }
}
.green-bg {
  background-color: #5cb465;
}
.green-text {
  color: #469c24;
}
.text-dark-gray {
  color: #231f20;
}
.special-gray {
  color: #575354;
}
@media only screen and (max-width: 768px) {
  .special-gray {
    color: #000;
  }
}
.height-calculation {
  height: calc(100% - 3rem);
}
.border-bottom {
  border-bottom: 2px solid #cfcfce;
}
.footer-text h4 {
  color: #000;
  margin-bottom: 1rem;
}

.footer-text p {
  font-size: 13px;
}

.footer-text h1 {
  color: #000;
  margin-bottom: 1rem;
}

.footer-text p {
  color: #000;
}

.footer-text a {
  color: #3c9eff;
  text-decoration: underline;
  cursor: pointer;
}
.contain-images img {
  object-fit: contain;
}
.blue-underline-link a {
  color: #054f7d;
  text-decoration: underline;
  cursor: pointer;
}
.light-gray-text {
  color: #838381;
}
@media only screen and (max-width: 768px) {
  .light-gray-text {
    color: #000;
  }
}
.gray-text {
  color: #3b3734;
}
.dark-purple-bg {
  background-color: #67338c;
}
@media only screen and (max-width: 768px) {
  h1 {
    font-size: 36px;
  }
  .mobile-text-black {
    color: #000;
  }
  .mobile-text-black span {
    color: #000;
  }
  h3 {
    font-size: 16px;
  }
}
.menu-button,
.menu-button::before,
.menu-button::after {
  z-index: 9999;
  display: block;
  background-color: #fff;
  position: absolute;
  height: 4px;
  width: 30px;
  transition: -webkit-transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}
.menu-button::before {
  content: "";
  margin-top: -8px;
}
.menu-button::after {
  content: "";
  margin-top: 8px;
}
#menu-toggle:checked + .menu-button-container .menu-button::before {
  margin-top: 0px;
  -webkit-transform: rotate(405deg);
  transform: rotate(405deg);
}
#menu-toggle:checked + .menu-button-container .menu-button {
  background: rgba(255, 255, 255, 0);
}
.close-menu-icon:after {
  display: inline-block;
  content: "×";
  width: 14px;
  height: 14px;
  font-size: 36px;
}
.white-rounded-border {
  border: 1px solid #fff;
  color: #000;
  text-align: center;
  border-radius: 20px;
  margin-left: 16px;
  margin-right: 16px;
  padding: 25px 20px;
}
.box-shadow {
  -webkit-filter: drop-shadow(0px 3px 6px #00000029);
  filter: drop-shadow(0px 3px 6px #00000029);
}
.light-grayish-bg {
  background: #f8f8f8 0% 0% no-repeat padding-box;
}
@media only screen and (max-width: 768px) {
  .writing-mode-vertical {
    -webkit-writing-mode: vertical-lr;
    -ms-writing-mode: tb-lr;
    writing-mode: vertical-lr;
  }
}
@media only screen and (max-width: 768px) {
  .rounded-\[36px\] {
    border-radius: 20px;
  }
}
.universum-background {
  background-image: url("../public/images/slice 18.png");
  background-repeat: no-repeat;
  border-radius: 20px;
  background-size: cover;
}
.color-light-gray {
  color: #646464;
}
@media only screen and (max-width: 768px) {
  .custom-h3 h3 {
    font-size: 16px;
  }
}
.scroll-disabled {
  overflow-y: hidden;
}


.headline {
  font-weight: 200 !important;
  line-height: 1.15em !important;
}

.orange-brands-bg {
  background-image: url("../public/images/orange-brands.jpg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
@media only screen and (max-width: 480px) {
  .orange-brands-bg {
    background-position: bottom;
    border-radius: 20px;
  }
}
.scroll-to-top {
  display: flex;
  width: 40px;
  height: 40px;
  background: #ca9f68;
  justify-content: center;
  border-radius: 100px;
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 9999;
  cursor: pointer;
}


@media only screen and (max-width: 480px) {
  .scroll-to-top {
    width: 26px;
    height: 26px;
    bottom: 30px;
  }
}
.user-dropdown {
  width: 100%;
  background-color: #fff;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000048;
}
.menu-border-bottom {
  border-bottom: 1px solid #cfcfce;
}
.menu-border-bottom:last-child {
  border-width: 0;
}
.backdrop-black {
  background-color: rgba(0, 0, 0, 0.8);
}
.steps-to-buy-modal {
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000048;
  border-radius: 20px;
  padding: 48px 32px 32px 32px;
  width: 350px;
  -webkit-transform: translateY(-30px);
  transform: translateY(-30px);
  max-height: calc(100vh - 116px);
  overflow: auto;
}

.steps-to-buy-modal::-webkit-scrollbar {
  display: none;
}

.steps-to-buy-modal span h4 {
  color: #000;
}
.white-border {
  border: 3px solid #fff;
}

.green-border {
  border: 1px solid #469c24;
}
.new-green-bg {
  background-color: #469c24;
}
.height-width {
  height: 100vh;
  width: 100vw;
}
.border-radius-14 {
  border-radius: 14px;
}
.height-correction {
  height: -webkit-max-content;
  height: max-content;
}
@media only screen and (max-width: 1024px) {
  .height-correction {
    height: 450px;
  }
}

h3 {
  font-size: 20px;
  font-weight: 200;
  line-height: 1.25em;
}

@media only screen and (min-width: 1024px) {
  .lg\:text-3xl {
    font-size: 2.2em !important;
    line-height: 1.55em !important;
    font-weight: 200 !important;
  }
}

.mb-10 {
  margin-bottom: 1.5rem;
}

@media only screen and (min-width: 1024px) {
  .lg\:font-normal {
    font-weight: 200;
  }
}

.quotes p {
  font-size: 1.05em;
}

@media only screen and (min-width: 1024px) {
  .lg\:mt-10 {
    margin-top: 1.5rem;
  }
}

@media only screen and (min-width: 1024px) {
  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.25;
  }
}

.text-44 {
  font-size: 44px;
  line-height: 46px;
  font-weight: 200;
}

@media only screen and (min-width: 1024px) {
  .cpro-distribution {
    background-image: url("../public/images/graph_side_insert.svg");
    background-repeat: no-repeat;
    background-position: right bottom;
  }
}

/* @media only screen and (min-width: 1024px) {
  .calc-overlay-width {
    width: calc(100vw - 17px);
  }
}
@media only screen and (max-width: 1024px) {
  .calc-overlay-width {
    width: calc(100vw);
  }
} */


.StripeElement {
  margin: 6px auto 10px auto;
  padding: 10px 12px;
  color: #32325d;
  background-color: white;
  border: 1px solid #00000033;
  border-radius: 4px;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.product {
  width: 100%;
  max-width: 450px;
  margin: auto;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  overflow: hidden;
}

.btn-pay {
  display: block;
  width: 100%;
  border: none;
  background: linear-gradient(
    135deg,
    rgb(49, 0, 62) 0%,
    rgb(195, 40, 110) 100%
  );
  color: #fff;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
}

.product-info {
  padding: 0 16px;
}

h3.product-title {
  font-size: 28px;
  margin-bottom: 15px;
}

h4.product-price {
  font-size: 24px;
  margin: 0;
  margin-bottom: 30px;
  color: #777;
  font-weight: 500;
}

.Toastify__toast-body > div {
  color: black;
}

.Toastify__close-button > svg {
  fill: black;
}

.stripe-form label{
  color: black;
}


.Toastify {
  z-index: 1000000;
  position: absolute;
}


.buy-cpro-modal-width {
  max-width: 536px;
}

@media only screen and (max-width: 720px) {
  .buy-cpro-modal-width {
    max-width: calc(100vw - 40px);
  }
}


.button {
  position: relative;
  cursor: pointer;
}

.button__text {
  color: #ffffff;
  transition: all 0.2s;
}

.button--loading .button__text {
  visibility: hidden;
  opacity: 0;
}

.button--loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}